import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Card from "~/components/styled/card";
//import ListSlider from "~/components/styled/ListSlider";
import Image from "~/components/image";
import { formatPrice } from "~/helpers/currency-formatter";

const TourList = ({ tours, gridCols }) => {
	// Define UTM parameters
	const viewDetailsUTM = new URLSearchParams({
		utm_source: "landing",
		utm_medium: "cta-button",
		utm_campaign: "view-details",
	}).toString();

	// State for the list
	const [list, setList] = useState([...tours.slice(0, 6)]);

	// State to trigger oad more
	const [loadMore, setLoadMore] = useState(false);

	// State of whether there is more to load
	const [hasMore, setHasMore] = useState(tours.length > 6);

	// Load more button click
	const handleLoadMore = () => {
		setLoadMore(true);
	};

	// Handle loading more articles
	useEffect(() => {
		if (loadMore && hasMore) {
			const currentLength = list.length;
			const isMore = currentLength < tours.length;
			const nextResults = isMore
				? tours.slice(currentLength, currentLength + 6)
				: [];
			setList([...list, ...nextResults]);
			setLoadMore(false);
		}
	}, [loadMore, hasMore]); //eslint-disable-line

	//Check if there is more
	useEffect(() => {
		const isMore = list.length < tours.length;
		setHasMore(isMore);
	}, [list]); //eslint-disable-line

	return (
		<div className={`grid ${gridCols} gap-6`}>
			{list.map((tour) => {
				return (
					<div className="relative mb-12 ">
						<Card key={tour.id}>
							<Link to={`/tours/${tour.slug}`} key={tour.id}>
								<Image
									alt="Tour Image"
									className="rounded-t-md transform border-gray-200 border-b"
									loading="lazy"
									image={tour.featuredImage}
								/>
								{tour.popular == true && (
									<div className="absolute  top-3 left-3 bg-getaway  rounded-full px-3 py-1 ">
										<p className=" text-black text-xs leading-relaxed font-medium uppercase  ">
											Bestseller
										</p>
									</div>
								)}
								{tour.new == true && (
									<div className="absolute  top-3 right-3 bg-new  rounded-full px-3 py-1 ">
										<p className=" text-white text-xs leading-relaxed font-medium uppercase  ">
											New
										</p>
									</div>
								)}

								{/* specialities */}
								{/* {tour.specialities &&
									tour.specialities.map((sp) => {
										return (
											<div key={sp.id}>
												{sp.slug == "e-bikes-available" && (
													<div className="absolute  top-3 right-3 bg-inclusions  rounded-full px-3 py-1 ">
														<p className=" text-black text-xs leading-relaxed font-medium uppercase  ">
															{sp.name}
														</p>
													</div>
												)}
											</div>
										);
									})} */}

								{/* <ListSlider slides={tour.slider} /> */}

								<div className="px-4 py-2 ">
									<div className="mb-1">
										<h3 className="normal-case hover:text-primary">{tour.title}</h3>
									</div>
									<div className="flex mb-4">
										{/* {node.destinations && node.destinations.map(destinations => {
                  return (
                  <div key={destinations.id}>
                  <p className="text-xs border rounded-full p-0.5 px-2 mr-1">{destinations.name}</p>
                  </div>
                )})} */}
										{tour.regions &&
											tour.regions.map((regions) => {
												return (
													<div key={regions.id}>
														<p className="text-xs text-gray-600 border rounded-full p-0.5 px-2 mr-1">
															{regions.name}
														</p>
													</div>
												);
											})}

										{/* {tour.tourType && (
											<p className="text-xs text-gray-600 border rounded-full p-0.5 px-2">
												{tour.tourType === "multiday" ? "Guided" : "Self-guided"}
											</p>
										)} */}
										{tour.tourType && (
											<p className="text-xs text-gray-600 border rounded-full p-0.5 px-2">
												{tour.tourType === "selfguided" ? "Self-guided" : "Guided"}
											</p>
										)}
										{/* {tour.activity && (
											<p className="text-xs text-gray-500 border rounded-full p-0.5 px-2">
												{tour.activity.name}
											</p>
										)} */}
									</div>
									<div>
										<p className="text-base font-semibold self-end">
											<span className="capitalize">{tour.duration}</span>{" "}
											<span className="text-sm text-gray-500 font-normal">from</span>{" "}
											<span>{tour.price && formatPrice(tour.price)}</span>
										</p>
									</div>
									<div>
										<p className="text-sm text-gray-500 lowercase">
											stay at{" "}
											<span className="text-base text-gray-800">
												{tour.quickfacts.lodging} inns
											</span>
										</p>
									</div>
									{/* <div className="flex items-baseline">
										<p className="text-sm text-gray-500 lowercase">
											stay at{" "}
											<span className="text-base text-gray-800">
												{tour.quickfacts.lodging} inns
											</span>
										</p>
										<div>
											{tour.quickfacts.lodging && (
												<span className="text-base text-gray-500">, </span>
											)}
										</div>
										<div>
											{tour.quickfacts.lodging && (
												<span className="text-base text-gray-500 lowercase">
													{tour.quickfacts.lodging} inns
												</span>
											)}
										</div>
									</div> */}
									<div className="flex items-baseline">
										{tour.quickfacts.level && (
											<div className="text-sm text-gray-500 lowercase">
												{tour.quickfacts.level.split("_").join(" to ")} level
											</div>
										)}
									</div>

									<button className="bg-primary2 hover:bg-primary  tracking-wide text-sm my-4 uppercase font-light text-white w-full py-2 rounded-md">
										View Details
									</button>
								</div>
							</Link>
						</Card>
					</div>
				);
			})}

			{/* Load more */}
			<div className=" items-start">
				{hasMore ? (
					<button
						onClick={handleLoadMore}
						className=" w-auto h-full py-3 px-6 md:py-4 md:px-8 font-medium text-white bg-black rounded-full"
					>
						View more tours
					</button>
				) : (
					<p className="text-landing uppercase">End of tours</p>
				)}
			</div>
		</div>
	);
};

TourList.propTypes = {
	tours: PropTypes.array,
	gridCols: PropTypes.string,
};

TourList.defaultProps = {
	gridCols: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
};

export default TourList;
